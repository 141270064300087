<template>
  <div class="container">
    <div class="banner"></div>

    <div class="box1">
      <div class="title">行业监管</div>
      <div class="tab flex">
        <div class="item" v-for="(item,index) in tabList" :class="index === active ? 'active' : '' "
             @click="tabChange(item,index)">{{ item.name }}
        </div>
      </div>
      <div class="list flex flex-wrap">
        <div class="item" v-for="item in itemList">
          <div class="flex">
            <img class="item-icon" :src="item.icon" alt=""/>
            <div class="flex">
              <div><span class="item-title">{{ item.title }}</span> <span class="item-content">{{ item.content }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box2">
      <div class="title">企业管理</div>
      <div class="list flex flex-between">
        <div class="item" v-for="item in list3">
          <img :src="item" alt=""/>
        </div>
      </div>
    </div>

    <div class="box3">
      <div class="title">社会营运</div>
      <template v-for="(item,index) in list4">
        <div class="left" v-if="index === activeTab">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-content"> {{ item.content }}</div>
          <img :src="item.icon" alt=""/>
        </div>
      </template>
      <div class="right">
        <div v-for="(item,index) in list4" class="item" :class="activeTab === index ? 'active' : ''"
             @click="tabChange2(item,index)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const list = [
  {
    icon: require('@/assets/system/item1.png'),
    title: "车辆实时定位：",
    content: "通过GPS、北斗等卫星定位系统，实时获取包车客运车辆的位置、速度、行驶方向等信息，方便监管部门掌握车辆的实时动态。"
  },
  {
    icon: require('@/assets/system/item5.png'),
    title: "车辆异常情况报警：",
    content: "监管端可以设置车辆异常情况的报警阈值，如超速、疲劳驾驶、异常停车等，当车辆出现异常情况时，监管端会自动发出警报，提醒监管部门及时处理。"
  },
  {
    icon: require('@/assets/system/item2.png'),
    title: "车辆行驶轨迹查询：",
    content: "监管端可以查询包车客运车辆的历史行驶轨迹，以便了解车辆的行驶路线、停留地点等情况，为事故处理、投诉调查等提供依据。"
  },
  {
    icon: require('@/assets/system/item6.png'),
    title: "网上执法：",
    content: "实现执法信息的发布、传递、查询、审批等功能，可以方便快捷地进行执法工作。"
  },
  {
    icon: require('@/assets/system/item3.png'),
    title: "车辆速度监控：",
    content: "监管端可以实时监控车辆的速度，对超速行为进行预警和处理，以确保行车安全。"
  },

  {
    icon: require('@/assets/system/item7.png'),
    title: "车内视频监控：",
    content: "包车客运车辆会配备车内视频监控系统，监管端可以实时查看车内情况，以确保乘客安全和维护车内秩序。"
  },

  {
    icon: require('@/assets/system/item4.png'),
    title: "车辆行驶状态监控：",
    content: "监管端可以实时监控车辆的行驶状态，如行驶、停车、熄火等，以便了解车辆的运行情况。"
  },
]
const list2 = [
  {
    icon: require('@/assets/system/item1.png'),
    title: "企业信息管理：",
    content: "可以查看客运企业的基本信息，如企业名称、统一社会信用代码、联系方式等，同时企业也可以在系统中更新和维护自己的信息。"
  },
  {
    icon: require('@/assets/system/item6.png'),
    title: "运营监控：",
    content: "可以实时监控企业的运营情况，如车辆行驶轨迹、驾驶员操作规范等，确保企业的运营安全。"
  },
  {
    icon: require('@/assets/system/item2.png'),
    title: "车辆管理：",
    content: "可以实时查看企业的车辆信息，包括车型、车牌号、车辆状态（在营、停运等）等，企业也可以在系统中提交新增、变更、报废等申请。"
  },
  {
    icon: require('@/assets/system/item7.png'),
    title: "违规管理：",
    content: "可以查看企业的违规记录，如超速、疲劳驾驶、不按线路行驶等，企业需要针对违规行为进行整改，并提交整改报告。"
  },
  {
    icon: require('@/assets/system/item3.png'),
    title: "驾驶员管理：",
    content: "可以查看企业的驾驶员信息，包括姓名、驾驶证号、驾驶证类型、联系方式等，企业也可以在系统中更新和维护驾驶员信息。"
  },
  {
    icon: require('@/assets/system/item8.png'),
    title: "通知公告：",
    content: "可以向企业发布通知公告，如政策法规、行业动态、安全提示等，帮助企业及时了解行业动态，提高安全管理水平。"
  },

  {
    icon: require('@/assets/system/item4.png'),
    title: "线路管理：",
    content: "可以查看企业的线路信息，包括起讫点、途经地、里程、预计行驶时间等，企业也可以在系统中提交新增、变更、取消等申请。"
  },
  {
    icon: require('@/assets/system/item9.png'),
    title: "数据统计与分析：",
    content: "可以对企业的业务数据进行统计和分析，如班次数量、客流分布、营收情况等，为企业制定经营策略提供数据支持。"
  },
  {
    icon: require('@/assets/system/item5.png'),
    title: "业务数据查询：",
    content: "可以查询企业的业务数据，如班次、票务、营收等，帮助政府部门了解企业的经营状况，为企业提供参考依据。"
  },
]
export default {
  data() {
    return {
      active: 0,
      tabList: [
        {name: "静态数据管理", value: 0},
        {name: "动态监管", value: 1},
      ],
      itemList: list2,
      list3: [
        require('@/assets/system/icon1.png'),
        require('@/assets/system/icon2.png'),
        require('@/assets/system/icon3.png'),
        require('@/assets/system/icon4.png'),
        require('@/assets/system/icon5.png'),
        require('@/assets/system/icon6.png'),
        require('@/assets/system/icon7.png'),
        require('@/assets/system/icon8.png'),
      ],
      list4: [
        {
          name: "在线包车",
          value: 0,
          content: "提供一站式包车服务，包括景点接送、自由行、团队游等多样化出行选择。通过智能匹配与优质服务，让您的旅游包车更便捷、更舒适、更安全。",
          icon:require('@/assets/system/banner1.png')
        },
        {
          name: "违规举报",
          value: 1,
          content: '违规举报系统是一款方便乘客对包车客运服务中的违法行为进行举报的工具。通过该系统，用户可以快速、准确地反映问题，帮助监管部门及时查处违规行为，保障乘客权益，提升行业服务质量。',
          icon:require('@/assets/system/banner2.png')
        },
        {
          name: "订单评价",
          value: 2,
          content: "订单评价系统是一个便捷高效的评价工具，旨在为乘客提供对司机服务水平、车辆舒适度以及整体旅程满意度的反馈平台，帮助用户找到更称心如意的包车服务，同时激励司机提升服务质量，共创优质的出行体验。",
          icon:require('@/assets/system/banner3.png')
        },
        {
          name: "信用体系",
          value: 3,
          content: "信用体系系统致力于构建安全、诚信的出行环境，通过智能评分、实名认证、信用评级等功能，确保乘客身份真实可靠，为用户提供优质、放心的包车服务。",
          icon:require('@/assets/system/banner4.png')
        },
      ],
      activeTab: 0
    }
  },
  methods: {
    tabChange(item, index) {
      this.active = index
      if (index === 0) {
        this.itemList = list2
      } else {
        this.itemList = list
      }
    },
    tabChange2(item, index) {
      this.activeTab = index
    }
  },

}
</script>

<style lang="scss" scoped>
.container {
  .banner {
    height: 410px;
    background: url("https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/Slice%2049.png") no-repeat;
    background-size: 100% 100%;
  }

  .box1 {
    width: 1920px;
    height: 890px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 49px 0 88px;

    .title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 42px;
      margin-bottom: 46px;
    }

    .tab {
      padding: 0 100px;
      //border-bottom: 2px solid #D9D9D9;
      margin-bottom: 28px;

      .item {
        font-size: 28px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
        flex: 1;
        padding-bottom: 20px;
        position: relative;
        cursor: pointer;

        &::after {
          display: inline-block;
          content: "";
          width: 100%;
          height: 12px;
          background: #C6D0FF;
          //position: absolute;
          //bottom: -6px;
          //left: 120px;
        }

        &.active {
          &::after {
            background: #4162FF;
          }
        }
      }
    }

    .list {
      width: 100%;
      height: 540px;
      padding: 0 110px;

      .item {
        width: 870px;
        text-align: left;
        margin-right: 20px;


        .item-icon {
          align-self: start;
          margin-right: 10px;
        }

        .item-title {
          flex-shrink: 0;
          font-size: 26px;
          color: #333;
          line-height: 60px;
        }

        .item-content {
          font-size: 20px;
          color: #333;
          line-height: 60px;
        }
      }
    }
  }

  .box2 {
    width: 1920px;
    height: 524px;
    box-sizing: border-box;
    background: url("https://imagepub.chinau8.cn/tianhua_jinhua/bg.png") no-repeat;
    padding: 70px 155px;
    position: relative;

    .title {
      font-size: 36px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 42px;
      margin-bottom: 62px;
    }

    .list {
      .item {
        width: 169px;
        height: 180px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px 0px 0px 0px;
        img{
          width: 100%;
        }
      }


    }
  }

  .box3 {
    height: 824px;
    background: #fff;
    box-sizing: border-box;
    padding: 60px 210px 0 295px;
    position: relative;

    .title {
      font-size: 36px;
      font-weight: bold;
      color: #333;
      line-height: 42px;
      margin-bottom: 62px;
    }

    .left {
      width: 950px;
      text-align: left;

      .item-name {
        font-size: 36px;
        font-weight: 500;
        color: #000000;
        line-height: 46px;
        margin-bottom: 20px;
      }

      .item-content {
        font-size: 26px;
        font-weight: 400;
        color: #000000;
        line-height: 46px;
        margin-bottom: 98px;
      }
    }

    .right {
      width: 319px;
      height: 552px;
      background: #FFFFFF;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.11);
      border-radius: 8px 8px 8px 8px;
      position: absolute;
      right: 210px;
      top: 165px;

      .item {
        height: 138px;
        font-size: 36px;
        font-weight: 500;
        color: #666666;
        line-height: 138px;
        text-align: center;
        cursor: pointer;

        &.active {
          width: 319px;
          height: 138px;
          background: #4162FF;
          border-radius: 8px 8px 8px 8px;
          color: #fff;
        }
      }
    }
  }
}
</style>
